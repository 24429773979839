// src/components/Alert.js
import React from 'react';
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const Alert = ({ type, message }) => {
  let icon;
  let bgColor;
  let textColor;

  switch (type) {
    case 'success':
      icon = <CheckCircleIcon className="h-6 w-6 text-green-500" />;
      bgColor = 'bg-green-100 dark:bg-green-800';
      textColor = 'text-green-800 dark:text-green-100';
      break;
    case 'error':
      icon = <XCircleIcon className="h-6 w-6 text-red-500" />;
      bgColor = 'bg-red-100 dark:bg-red-800';
      textColor = 'text-red-800 dark:text-red-100';
      break;
    case 'warning':
      icon = <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />;
      bgColor = 'bg-yellow-100 dark:bg-yellow-800';
      textColor = 'text-yellow-800 dark:text-yellow-100';
      break;
    default:
      icon = null;
      bgColor = 'bg-gray-100 dark:bg-gray-700';
      textColor = 'text-gray-800 dark:text-gray-100';
  }

  return (
    <div className={`${bgColor} p-4 rounded-md flex items-center space-x-2`}>
      {icon}
      <span className={`${textColor}`}>{message}</span>
    </div>
  );
};

export default Alert;
