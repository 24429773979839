// src/components/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DeviceTabletIcon } from '@heroicons/react/24/solid'; // Use DeviceTabletIcon as placeholder

const Home = () => {
  const navigate = useNavigate();

  const handleSelectUSB = (usbId) => {
    navigate(`/dashboard/${usbId}`);
  };

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white">
        Select a USB Device
      </h1>
      <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
        {/* USB1 - Real Data */}
        <div
          onClick={() => handleSelectUSB('usb1')}
          className="flex flex-col items-center p-8  dark:bg-gray-800 shadow-lg rounded-lg cursor-pointer hover:shadow-2xl transition-shadow duration-300"
        >
          <DeviceTabletIcon className="h-20 w-20 text-blue-600 dark:text-blue-400 mb-4" />
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">USB1 - Real Data</h2>
        </div>
        {/* USB2 - Dummy Data */}
        <div
          onClick={() => handleSelectUSB('usb2')}
          className="flex flex-col items-center p-8 dark:bg-gray-800 shadow-lg rounded-lg cursor-pointer hover:shadow-2xl transition-shadow duration-300"
        >
          <DeviceTabletIcon className="h-20 w-20 text-green-600 dark:text-green-400 mb-4" />
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">USB2 - Dummy Data</h2>
        </div>
      </div>
    </div>
  );
};

export default Home;
