import React, { createContext, useContext, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import useWebSocket from "react-use-websocket";
import { HiExclamationCircle } from "react-icons/hi"; // Importing an icon (you can choose other icons too)

// Create WebSocket Context
const WebSocketContext = createContext();

// Hook to access WebSocket context
export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

// WebSocket Provider
export const WebSocketProvider = ({ children }) => {
  const { lastMessage } = useWebSocket("wss://ws.usbmonitor.tech/ws", {});

  useEffect(() => {
    if (lastMessage) {
      try {
        const data = JSON.parse(lastMessage.data);
        // Handle different types of messages
        if (data.message) {
            toast.error(
                <div className="flex items-center">
                  <HiExclamationCircle className="mr-2 text-2xl" /> 
                  Warning: there is anomaly detection
                </div>,
                {
                  duration: 10000, // Duration set to 10 seconds
                  style: {
                    fontSize: "18px", // Modern font size
                    padding: "16px",  // Adequate padding for a modern look
                    borderRadius: "8px", // Rounded corners
                    backgroundColor: "red", // Red background
                    color: "white", // White text
                  },
                }
              ,{icon: "❗️"});
        } else if (data.warning) {
            toast.error(`Warning there is anomaly detection`);
        } else if (data.success) {
            toast.error(`Warning there is anomaly detection`);
        } else {
          toast(`Unknown event received.`);
        }
      } catch (err) {
        console.error("Error parsing JSON:", err);
        toast.error("Received invalid JSON data.");
      }
    }
  }, [lastMessage]);

  return (
    <WebSocketContext.Provider value={{}}>
      {children}
      <Toaster
        position="top-center"
        toastOptions={{
            style: {
            fontSize: "20px", // Default font size for all toasts
            padding: "20px",  // Default padding for all toasts
            borderRadius: "10px", // Default border radius
            backgroundColor: "rgba(255, 0, 0, 0.9)", // Default background color
            color: "white", // Default text color
            },
        }}
        />
    </WebSocketContext.Provider>
  );
};
