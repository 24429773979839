// src/components/Navbar.js
import React, { useContext } from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext'; // Import ThemeContext

const Navbar = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext); // Consume ThemeContext

  return (
    <nav className="bg-blue-600 dark:bg-gray-800 p-4 shadow-md">
      <div className="container mx-auto flex items-center justify-between">
        <h1 className="text-white text-xl font-bold">USB Monitor Dashboard</h1>
        <div className="flex items-center space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          <button
            onClick={toggleDarkMode}
            className="flex items-center text-white px-3 py-2 rounded-md hover:bg-blue-700 dark:hover:bg-gray-700 focus:outline-none transition-colors duration-300"
          >
            {darkMode ? (
              <>
                <SunIcon className="h-5 w-5 mr-1" />
                Light Mode
              </>
            ) : (
              <>
                <MoonIcon className="h-5 w-5 mr-1" />
                Dark Mode
              </>
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
