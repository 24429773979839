// src/components/Dashboard.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext'; // Import ThemeContext

const Dashboard = () => { // No need to receive darkMode as a prop
  const { usbId } = useParams();
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext); // Consume ThemeContext
  
  // Initialize state with default values
  const initialstate = useState({
    latestLog: {
      status: 'unknown',
      ip: 'N/A',
      lastEvent: 'N/A',
      timestamp: 'N/A',
      operations:'N/A'
    },
    metrics: {
      mostEncryptedFolders: [],
      totalEncryptionOperations: 0,
      totalDecryptionOperations: 0,
      mostUsedIPs: []
    },
    eventHistory: []
  });
  const [data, setData] = initialstate
  const [error, setError] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  // Dummy data for USB2
  const dummyData = {
    latestLog: {
      status: 'active', // Possible values: 'active', 'inactive', 'error'
      ip: '192.168.1.100',
      lastEvent: 'Encryption Completed',
      timestamp: '2024-11-25T21:30:00Z',
      operations: 'Read'
    },
    metrics: {
      mostEncryptedFolders: [
        { folderName: 'Documents', encryptionCount: 10 },
        { folderName: 'Pictures', encryptionCount: 8 },
        { folderName: 'Videos', encryptionCount: 5 },
      ],
      totalEncryptionOperations: 15,
      totalDecryptionOperations: 7,
      mostUsedIPs: [
        { ipAddress: '192.168.1.101', usageCount: 12 },
        { ipAddress: '192.168.1.102', usageCount: 9 },
        { ipAddress: '192.168.1.103', usageCount: 6 },
      ]
    },
    eventHistory: [
      { lastEvent: 'Encryption Started', ip: '192.168.1.101', timestamp: '2024-11-25T20:30:00Z' },
      { lastEvent: 'Encryption Completed', ip: '192.168.1.102', timestamp: '2024-11-25T20:45:00Z' },
      { lastEvent: 'Decryption Started', ip: '192.168.1.103', timestamp: '2024-11-25T21:00:00Z' },
    ]
  };

  // Utility function to validate and sanitize data
  const validateData = (responseData) => {
    return {
      latestLog: responseData.latestLog || {
        status: responseData.status,
        ip: responseData.ip,
        lastEvent: responseData.lastEvent,
        timestamp: responseData.timestamp,
        operations: responseData.operation
      },
      metrics: responseData.metrics || {
        mostEncryptedFolders: [],
        totalEncryptionOperations: 0,
        totalDecryptionOperations: 0,
        mostUsedIPs: []
      },
      eventHistory: responseData.eventHistory || []
    };
  };

  // Fetch data based on USB selection
  useEffect(() => {
    if (usbId === 'usb1') {
      fetchRealData();
      const interval = setInterval(fetchRealData, 5000); 
    } else if (usbId === 'usb2') {
      setData(dummyData);
      setError(null);
    } else {
      setError('Invalid USB selection.');
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usbId]);

  const fetchRealData = async () => {
    try {
      const response = await axios.get('https://n3hobbddbh.execute-api.us-east-1.amazonaws.com/default/fetch-logs'); // Replace with your actual endpoint
      const responseData = response.data;
      console.log('API Response:', responseData);
      setData(validateData(responseData));
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data.');
    }
  };

  // Handle Delete Button Click
  const handleDelete = async () => {
    if (usbId !== 'usb1') return; // Only allow delete for USB1

    const confirmDelete = window.confirm("Are you sure you want to delete the ESP32 data?");
    if (!confirmDelete) return;

    setDeleteLoading(true);
    setDeleteMessage(null);

    try {
      const apiEndpoint = 'https://q079npht75.execute-api.us-east-1.amazonaws.com/default/delete'; // Your API Gateway endpoint

      const response = await axios.post(apiEndpoint, {});

      if (response.status === 200) {
        setDeleteMessage({ type: 'success', text: 'Delete command sent successfully.' });
      } else {
        setDeleteMessage({ type: 'error', text: 'Unexpected response from the server.' });
      }
    } catch (err) {
      console.error('Error sending delete command:', err);
      setDeleteMessage({ type: 'error', text: 'Failed to send delete command.' });
    }

    setDeleteLoading(false);
  };

  if (data === initialstate) {
    return <LoadingSpinner />;
  }
  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Alert type="error" message={error} />
        <button
          onClick={() => navigate('/')}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
        >
          Go Back
        </button>
      </div>
    );
  }

  // Destructure data with default values
  const { latestLog = {}, metrics = {}, eventHistory = [] } = data;

  // Safely access properties with fallback values
  const usbStatus = latestLog.status ?? 'unknown'; // 'active', 'inactive', or other statuses
  const ipAddress = latestLog.ip ?? 'N/A';
  const lastEvent = latestLog.lastEvent ?? 'N/A';
  const timestamp = latestLog.timestamp ? new Date(latestLog.timestamp).toLocaleString() : 'N/A';
  const operations =latestLog.operation ?? 'N/A';

  // Determine icon and message based on USB status
  let statusIcon;
  let statusMessage;
  
  if (usbStatus.toLowerCase() === 'active'.toLowerCase()) {
    statusIcon = <CheckCircleIcon className="h-6 w-6 text-green-500 ml-2" />;
    statusMessage = "USB device is active and connected.";
  } else if (usbStatus.toLowerCase() === 'inactive'.toLowerCase()) {
    statusIcon = <XCircleIcon className="h-6 w-6 text-red-500 ml-2" />;
    statusMessage = "USB device is inactive or disconnected.";
  } else {
    statusIcon = <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 ml-2" />;
    statusMessage = "Error fetching USB status.";
  }

  // Define chart colors based on darkMode
  const chartStroke = darkMode ? '#ffffff' : '#000000';
  const chartGridStroke = darkMode ? '#374151' : '#e5e7eb';
  const tooltipBg = darkMode ? '#1f2937' : '#ffffff';
  const tooltipText = darkMode ? '#ffffff' : '#000000';
  const barFillEncryption = darkMode ? '#4F46E5' : '#8884d8';
  const barFillDecryption = darkMode ? '#10B981' : '#ffc658';
  const barFillUsage = darkMode ? '#818cf8' : '#8884d8';

  return (
    <div className="container mx-auto px-4 py-8">
      <button
        onClick={() => navigate('/')}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
      >
        &larr; Back to Home
      </button>

      <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">USB Device Monitoring Dashboard</h1>

      {/* USB Status Card */}
      <div className={` dark:bg-gray-800 shadow-md rounded-lg p-6 mb-8 border-l-4 transition-colors duration-500 ${
        usbStatus.toLowerCase() === 'active'.toLowerCase() ? 'border-green-500' :
        usbStatus.toLowerCase() === 'inactive'.toLowerCase() ? 'border-red-500' :
        'border-yellow-500'
      }`}>
        <h5 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white flex items-center">
          USB Status: {statusIcon}
        </h5>
        <Alert type={usbStatus.toLowerCase() === 'active'.toLowerCase() ? 'success' : (usbStatus.toLowerCase() === 'inactive'.toLowerCase() ? 'error' : 'warning')} message={statusMessage} />
        <p className="text-gray-700 dark:text-gray-300 mb-2">Operations: {operations}</p>
        <p className="text-gray-700 dark:text-gray-300 mb-2">IP Address: {ipAddress}</p>
        <p className="text-gray-700 dark:text-gray-300 mb-2">Last Event Time: {timestamp}</p>
        <p className="text-gray-700 dark:text-gray-300">Last Event: {lastEvent}</p>
      </div>

      {/* Delete Button */}
      <div className="mb-8">
        <button
          onClick={handleDelete}
          disabled={deleteLoading || usbId !== 'usb1'}
          className={`px-6 py-3 font-semibold text-white rounded-lg transition-colors duration-300 ${
            deleteLoading || usbId !== 'usb1' ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'
          }`}
        >
          {deleteLoading ? 'Sending...' : 'Delete ESP32 Data'}
        </button>
        {deleteMessage && (
          <div className="mt-4">
            <Alert type={deleteMessage.type} message={deleteMessage.text} />
          </div>
        )}
      </div>

      {/* Metrics and Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

        {/* Most Encrypted Folders */}
        <div className=" dark:bg-gray-800 shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Most Encrypted Folders</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={metrics.mostEncryptedFolders}>
              <CartesianGrid strokeDasharray="3 3" stroke={chartGridStroke} />
              <XAxis dataKey="folderName" stroke={chartStroke} />
              <YAxis allowDecimals={false} stroke={chartStroke} />
              <Tooltip
                contentStyle={{
                  backgroundColor: tooltipBg,
                  border: 'none',
                }}
                labelStyle={{
                  color: tooltipText,
                }}
                itemStyle={{
                  color: tooltipText,
                }}
              />
              <Legend wrapperStyle={{ color: tooltipText }} />
              <Bar dataKey="encryptionCount" fill={barFillEncryption} name="Encryption Count" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Total Encryption and Decryption Operations */}
        <div className=" dark:bg-gray-800 shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Encryption & Decryption Operations</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={[{
              name: 'Operations',
              Encryption: metrics.totalEncryptionOperations,
              Decryption: metrics.totalDecryptionOperations,
            }]}>
              <CartesianGrid strokeDasharray="3 3" stroke={chartGridStroke} />
              <XAxis dataKey="name" stroke={chartStroke} />
              <YAxis allowDecimals={false} stroke={chartStroke} />
              <Tooltip
                contentStyle={{
                  backgroundColor: tooltipBg,
                  border: 'none',
                }}
                labelStyle={{
                  color: tooltipText,
                }}
                itemStyle={{
                  color: tooltipText,
                }}
              />
              <Legend wrapperStyle={{ color: tooltipText }} />
              <Bar dataKey="Encryption" fill={barFillEncryption} name="Encryption" />
              <Bar dataKey="Decryption" fill={barFillDecryption} name="Decryption" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Most Used IP Addresses */}
        <div className=" dark:bg-gray-800 shadow-md rounded-lg p-6 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Most Used IP Addresses</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={metrics.mostUsedIPs}>
              <CartesianGrid strokeDasharray="3 3" stroke={chartGridStroke} />
              <XAxis dataKey="ipAddress" stroke={chartStroke} />
              <YAxis allowDecimals={false} stroke={chartStroke} />
              <Tooltip
                contentStyle={{
                  backgroundColor: tooltipBg,
                  border: 'none',
                }}
                labelStyle={{
                  color: tooltipText,
                }}
                itemStyle={{
                  color: tooltipText,
                }}
              />
              <Legend wrapperStyle={{ color: tooltipText }} />
              <Bar dataKey="usageCount" fill={barFillUsage} name="Usage Count" />
            </BarChart>
          </ResponsiveContainer>
        </div>

      </div>

      {/* Event History */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Event History</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full  dark:bg-gray-800 shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 dark:bg-gray-600 text-gray-600 dark:text-gray-200 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Event</th>
                <th className="py-3 px-6 text-left">IP Address</th>
                <th className="py-3 px-6 text-left">Timestamp</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 dark:text-gray-300 text-sm font-light">
              {eventHistory.length > 0 ? (
                eventHistory.map((event, index) => (
                  <tr key={index} className="border-b border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300">
                    <td className="py-3 px-6 text-left whitespace-nowrap">{event.lastEvent ?? 'N/A'}</td>
                    <td className="py-3 px-6 text-left">{event.ip ?? 'N/A'}</td>
                    <td className="py-3 px-6 text-left">{event.timestamp ? new Date(event.timestamp).toLocaleString() : 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr className="bg-gray-100 dark:bg-gray-800">
                  <td className="py-3 px-6 text-center text-gray-700 dark:text-gray-300" colSpan="3">
                    No event history available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
