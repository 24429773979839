// src/components/LoadingSpinner.js
import React from 'react';
import { BeakerIcon } from '@heroicons/react/24/solid';

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center py-20">
      <BeakerIcon className="animate-spin h-10 w-10 text-blue-500 dark:text-blue-300" />
      <span className="ml-4 text-gray-700 dark:text-gray-300 text-lg">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
